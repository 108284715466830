<template>
  <div>
    <b-row no-gutters>
      <b-col class="col-12 col-md-6 pl-3 pl-md-4">
        <h2 class="text-gray">{{ $t( translationPath + 'title_manage_assets') }}</h2>
      </b-col>
      <b-col class="col-12 col-md-6 pr-3 pr-md-4">
        <b-link class='btn btn-xs btn-outline-secondary float-right mb-2 mb-md-0' to='/assets-liabilities/accounts/create/asset'>
          <i class="flaticon stroke plus"></i> {{ $t(translationPath + 'new_asset') }}
        </b-link>
        <b-link
          class='btn btn-xs btn-outline-secondary float-right mr-1 mr-xs-0 mr-sm-2 mr-md-3 mb-2 mb-md-0'
          to='/assets-liabilities/accounts/groups/create/asset'>
          <i class="flaticon stroke plus"></i>
          {{ $t( translationPath + 'new_asset_group') }}
        </b-link>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12" class="pl-4">
        <p class="text-gray">{{ $t( translationPath + 'table_description') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AccountPlanAssetsHeader',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  }
}
</script>
